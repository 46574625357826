<script lang="ts">
	// Icons from https://github.com/FortAwesome/Font-Awesome/tree/master/svgs/solid
	// did not work
	// import '../../../httpdocs/fontello/css/fontello.css';
	
	enum GROUP_IDS {
		COMPOSITIONS = 'compositions',
		COMPOSERS = 'composers',
	}
	
	enum STATES {
		NORMAL = "0",
		SMALL = "1",
		HOMEPAGE = "2"
	}
	
	type AutoCompleteGroup = {
		id: string,
		label: string,
		items: Array<AutoCompleteItem>
	}
	
	type AutoCompleteItem = {
		id: string,
		composition: string,
		composer: string,
		value: string,
		url: string,
		index: number
	};

	export let id: string = '';
	export let action: string = '';
	export let url_api: string = '';
	export let url_proto_composition = '';
	export let url_proto_composer = '';
	export let value: string = '';
	export let placeholder: string = '';
	export let search_button_title: string = '';
	export let delay_search: number = 1300;
	export let min_chars: number = 3;
	export let is_phone = false;
	export let state: string = "0";
	
	let inputSearch: HTMLInputElement;

	let result: Array<AutoCompleteGroup> = [];

	/**
	 * Starting from 1. 0 means that nothing is selected
	 */
	let selectIndex: number = 0;

	let timer: number;
	
	let listHidden: boolean = false;
	
	let _stylesInput = {
		// 'background-color': '#FFFFFF'
	};
	let _stylesSearchButton = {};

	let _stylesSuggestions = {};
	
	if (is_phone) {
		// _stylesInput = {
		// 	'background-color': '#EEEEEE'
		// };
		_stylesSuggestions = {
			'width': '85%'
		};
		_stylesSearchButton = {
			'width': '47px'
		}
	}

	$: inputStyles = Object.entries(_stylesInput)
		.map(([key, value]) => `${key}:${value}`)
		.join(';');

	$: searchButtonStyles = Object.entries(_stylesSearchButton)
		.map(([key, value]) => `${key}:${value}`)
		.join(';');
	
	$: suggestionsStyles = Object.entries(_stylesSuggestions)
		.map(([key, value]) => `${key}:${value}`)
		.join(';');
	
	function onKeyDown(ev: KeyboardEvent): void {
		// limit index till max items and first element (or maybe 0, which will remove the selection completely)
		// console.log(`Some key down. ${ev.key}`);
		switch (ev.key) {
			case 'ArrowUp':
				if (selectIndex > 0) {
					selectIndex--;
				}
				break;
			case 'ArrowDown':
				if (numItems() > selectIndex) {
					selectIndex++;
				}
				break;
			case 'Enter':
					if (selectIndex > 0) {
						select(selectIndex);
						ev.preventDefault();
					}
				break;
			// case 'Escape':
			// 	hide();
			// 	break;
		}
	}
	
	function numItems(): number {
		let num = 0;
		result.forEach(group => {
			num += group.items.length;
		})
		return num;
	}
	
	function hide(): void {
		selectIndex = 0;
		listHidden = true;
	}

	function onInput(): void {
		if (typeof timer !== 'undefined') {
			window.clearTimeout(timer)
		}
		
		if (inputSearch.value.length >= min_chars) {
			timer = window.setTimeout(getData, delay_search);
		}
	}
	
	function onBlurInput() {
		// Hide with delay, because clicking on item is not handled otherwise
		setTimeout(() => {
			hide();
		}, 300);
	}

	function onFocusInput() {
		listHidden = false;
	}
	
	async function getData() {
		if (inputSearch.value.length < min_chars) {
			return;
		}
		
		const response = await fetch(url_api + inputSearch.value);
		// Slice to prevent too many results to be rendered
		let _result = (await response.json()).slice(0, 100);
		let c = 0;
		_result.forEach((group: AutoCompleteGroup) => {
			group.items.forEach((item: AutoCompleteItem) => {
				c++;
				item.index = c;
				if (group.id === GROUP_IDS.COMPOSERS) {
					item.url = url_proto_composer + item.id;
				} else {
					item.url = url_proto_composition + item.id;
				}
			})
		});
		
		result = _result;
	}
	
	function onClick(ev: Event) {
		ev.preventDefault();
		let target = <HTMLAnchorElement>ev.currentTarget;
		selectIndex = parseInt(target.getAttribute('data-index'));
		
		select(selectIndex);
	}
	
	function select(index) {
		result.forEach((group: AutoCompleteGroup) => {
			group.items.forEach((item: AutoCompleteItem) => {
				if (index === item.index) {
					// When selecting composition we will not go to the composition, but change the search content to "composer composition"
					// and redirect to the search page with the proper search content
					if (group.id === GROUP_IDS.COMPOSITIONS) {
						let _urlSearch = action + '?content=' + encodeURIComponent(item.composer + ': ' + item.composition);
						window.location.href = _urlSearch;
					} else {
						// var d = new Date();
						// d.setTime(d.getTime() + (30 * 24 * 3600000));
						// let cookie = 'lastCompositionDetailedSearchUrl=' + encodeURIComponent(action + '?content=' + inputSearch.value) + ';expires=' + d.toUTCString() + ';path=/;SameSite=Lax;Secure';
						// document.cookie = cookie;

						window.location.href = item.url;
					}
				}
			})
		});
	}
</script>

<svelte:options tag="search-autocomplete" />

<style lang="scss">
	@import '../../httpdocs/scss/themes/_common/config';
	$newSearchAutocompleteMaxWidth: 250px;

	form {
		width: 100%;
		margin: 0 auto;
		border: var(--border);
//		border: solid 1px $darkGrey;
		height: 100%;
		box-sizing: border-box;
		border-radius: var(--border-radius);
		box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
		background-color: var(--background-color);
	}
	button.search {
		height: $newSearchHeight;
		padding: 0;
		margin: 0;
//		border: var(--border);
		border-left: none;
		vertical-align: middle;
		background-color: var(--background-color);

		svg {
			fill: #777;
			box-sizing: border-box;
			margin: 0;
			height: 100%;
			padding: 3px;
			cursor: pointer;
			text-decoration: none;
		}
	}

	.newSearchSubWrapper {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-evenly;
		width: calc(100% - var(--border-radius));
		margin-left: calc(var(--border-radius) / 2);
		height: 100%;
		padding: 1px;
		box-sizing: border-box;
		// the & here is sass keyword. Will result "element + element", but not "element & + element"
		& > div {
			flex: 1 0 auto;
			width: min-content;
		}

		& > div:first-child {
			flex: 100 0 auto;
		}

		button.search {
			// width: $newSearchHeight - 2px;
			display: block;
//			height: 100%;
			// height: $newSearchHeight - 2px;
			height: 90%;
			border: none;
			text-align: center;

			svg {
				display: inline;
			}
		}
	}

	input.newSearchTextField {
		width: 100%;
		border: 0px;
//		height: $newSearchHeight;
		height: 100%;
		padding: 0 10px;
		border-right: none;
		vertical-align: middle;
		background-color: var(--background-color);
	}

	.newSearchTextField:focus {
		outline: none;
	}

	div.groups {
		position: absolute;
		z-index: 3;
		box-shadow: #aaa 2px 2px 10px;
		list-style-type: none;
		padding: 0;
		margin: 0;
		background-color: var(--background-color);
		width: $newSearchAutocompleteMaxWidth;
		
		div.groupWrapper {
			box-sizing: border-box;
			text-align: center;
			padding: 0;
			div.groupLabel {
				display: block;
				background-color: var(--background-color);
				color: $noemphColor;
				font-weight: normal;
				line-height: 2em;
			}
		}
		div.items {
			position: static;
			width: auto;
			span {
				color: $noemphColor;
				font-weight: bold;
			}
			button {
				display: block;
				width: 100%;
				background-color: $backgroundColor;
				color: $color;
				text-align: left;
				margin: 1px;
				padding: 0.5em;
				border: solid 1px $borderColor;
				border-top: none;
				white-space: normal;
			}
			
			button:hover {
				cursor: pointer;
			}

			button:hover, button.selected, button:hover span, button.selected span {
				background-color: $headerColor;
				color: $backgroundColor;
			}
		}
	}
	
	form.newSearchFormHomepage {
		display: flex;
		border: none;
		
		div.newSearchSubWrapper {
			flex-grow: 1;
			border: var(--border);
			box-sizing: border-box;
			border-radius: var(--border-radius);
			background-color: var(--background-color);
			padding: calc(var(--border-radius) / 2);
//			box-shadow: $medGrey 0 4px 4px;
			box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
			margin-left: 0;
		}

		//button.formButtonAlt {
		//	// Remove the default iPad buttons appearance. It was too rounded
		//	// Remove styling for other browsers too
		//	appearance: none;
		//	border-radius: 0.7rem;
		//	padding: 0 1rem;
		//	margin: 0 0 0 2em;
		//	cursor: pointer;
		//	font-size: 1rem;
		//	font-weight: bold;
		//	border-style: solid;
		//	border-width: 3px;
		//	line-height: 1.8rem;
		//	letter-spacing: 0.1ex;
		//	font-family: $headerFontFamily;
		//	text-decoration: none;
		//	text-transform: none;
		//	background-color: $backgroundColor;
		//	border-color: $linkColor;
		//	outline: transparent solid 1px;
		//	color: $linkColor;
		//	width: fit-content;
		//}

		//button.formButtonAlt:hover, button.formButtonAlt:focus-within {
		//	box-shadow: $darkGrey -4px 4px 4px;
		//	outline-color: $linkColor
		//}

		//button.formButtonAlt:active {
		//	background-color: $linkColor;
		//	color: $backgroundColor;
		//}

	}

	@media screen and (max-width: $responsiveWidthPhonePortrait) {
		form.newSearchFormHomepage {
//			flex-direction: column;
			align-items: end;
			
//			button.formButtonAlt {
////				margin-top: 18px;
//				margin-bottom: 3px;
//				margin-left: 1em;
//			}
		}
		
//		.newSearchSubWrapper {
//			height: auto;
//		}

	}
	
	div.groupsHomepage {
		width: auto;
		margin-left: var(--border-radius);
	}

</style>

<form id="{id}_form" action="{action}" method="get" class="{state === STATES.HOMEPAGE ? 'newSearchFormHomepage' : 'newSearchForm'}">
	<div class="newSearchSubWrapper">
		<div>
			 <input
				id="{id}_content"
				bind:this={inputSearch}
				on:keydown={onKeyDown}
				on:input={onInput}
				on:blur={onBlurInput}
				on:focus={onFocusInput}
				autocomplete="off"
				type="text"
				class="formEdit newSearchTextField"
				name="content"
				value="{value}"
				placeholder="{placeholder}"
				style="{inputStyles}"
			 /> 
		</div>
		<button class="search" title="{search_button_title}" style="{searchButtonStyles}">
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
				<path d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"/>
			</svg>
		</button>
		
		<!-- {if $shoppingCartButton}
			<div style="width: 55px; padding-top: 5px; border-left: 1px solid grey;">
				{ui instance="shoppingCartButton"}
			</div>
		{/if} -->
	</div>
	
	<!--{#if state === STATES.HOMEPAGE}-->
	<!--	<button class="formButtonAlt" title="{search_button_title}">{search_button_title}</button>-->
	<!--{/if}-->
	
</form>

{#if !listHidden}
	<div style="{suggestionsStyles}"  class="{state === STATES.HOMEPAGE ? 'groups groupsHomepage' : 'groups'}">
		{#each result as group, ig(group.id)}
			{#if group.items.length > 0}
				<div class="groupWrapper"
				><div class="groupLabel">{group.label}</div>
					<div class="items">
					{#each group.items as item, i(group.id + item.id)}
						{#if item.index === selectIndex}
							<button
								on:click={onClick}
								data-index="{item.index}"
								data-value="{item.value}"
								class="selected"
								><span>{item.composer}</span>{#if item.composition} : {item.composition}{/if}</button>
						{:else}
							<button
								on:click={onClick}
								data-index="{item.index}"
								data-value="{item.value}"
							><span>{item.composer}</span>{#if item.composition} : {item.composition}{/if}</button>
						{/if}
					{/each}
					</div>
				</div>
			{/if}
		{/each}
	</div>
{/if}
